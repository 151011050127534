<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Cash Deposit</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="create.territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.code + ' ' + dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.code + ' ' + dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Deposit Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date Deposit"
          v-model="date_deposit"
          placeholder="Date Deposit"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
          <!-- disabled-dates="{from: new Date()}" -->
      </div>
      <div class="vx-col w-full">
         <label class="vs-input--label">Cash Deposit Slip No</label>
          <vs-input
            class="w-full"
            v-model="create.deposit_number"
          />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Collection Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="date_clearing"
          placeholder="Date Clearing"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
      </div>

      <div class="vx-col w-full">
         <label class="vs-input--label">Deposit Value</label>
          <vs-input
            class="w-full"
            v-model="create.amount"
            @keyup="create.amount = formatPrice(create.amount.toString())"
          />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Method Management</label>
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="optionMethod"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :disabled="true"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>
      
      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="optionCustomer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          @search-change="getOptionCustomer"
          :disabled="false"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full"  v-if="!hideForm">
        <label class="vs-input--label">{{this.create.method}} Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date"
          v-model="date_giro"
          :placeholder="'Date '+create.method"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Bank</label>
        <multiselect
          class="selectExample"
          v-model="bank"
          :options="optionBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
        >
        </multiselect>
      </div>
      
      <div class="vx-col w-full" v-if="!hideForm">
         <label class="vs-input--label">Number Of {{this.create.method}}</label>
          <vs-input
            class="w-full"
            v-model="create.number_of"
          />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="ID"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.BankName + ' ' + dt.option.AccountName + ' ' + dt.option.AccountNumber }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.BankName + ' ' + dt.option.AccountName + ' ' + dt.option.AccountNumber }}</span>
            </div>
          </template>
        </multiselect>
      </div>
     
      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
         <vs-textarea v-model="create.note"/>
      </div>
      <div class="vx-col w-full">
        <br>
        <vs-button class="mb-2" v-on:click="handleSubmit">Save</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        create:{
          id:null,
          customer:null,
          amount:0,
          deposit_number:"",
          note:"",
          territory: null,
          date_clearing: null,
          method:null,
          date_deposit:null,
          operatingUnitBank: null,
          number_of:"",
        },
        hideForm:true,
        date_giro: null,
        date_deposit: null,
        date_clearing: null,
        bank:null,
        optionOperatingUnitBank:[],
        optionTerritory:[],
        optionCustomer:[],
        optionBank:[],
        optionMethod:['Cash','Giro','Cheque','Transfer'],
      };
    },
    formatPrice(angka, prefix = "") {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    checkForm() {
      if (this.create.operatingUnitBank == null){
         this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.create.amount == 0){
         this.$vs.notify({
          title: "Error",
          text: "value deposit must be greater than 0",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.create.deposit_number == ""){
         this.$vs.notify({
          title: "Error",
          text: "please fill deposit number",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.create.territory == null){
         this.$vs.notify({
          title: "Error",
          text: "please choose territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.date_clearing == null){
         this.$vs.notify({
          title: "Error",
          text: "please fill Collection Date",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.create.method == null){
         this.$vs.notify({
          title: "Error",
          text: "method can't be null",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      if (this.date_deposit == null){
         this.$vs.notify({
          title: "Error",
          text: "please fill date deposit",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false
      }
      return true
    },
    handleSubmit() {
      if (!this.checkForm()) {
        return
      }
      this.$vs.loading()
      this.create.deposit_value = parseInt(this.create.amount.toString().replace(/,/g, ''))
      this.create.territory_id = this.create.territory.id ?this.create.territory.id:this.create.territory.ID
      if (this.create.method == "Giro" || this.create.method == "Check" || this.create.method == "Transfer" || this.create.method == "Cheque") {
        this.create.customer_code = this.create.customer.code
        this.create.giro_bank_id = this.bank.ID
        this.create.giro_bank_name = this.bank.Name
        this.create.date_giro = moment(this.date_giro).format("DD MMMM YYYY")
      }
      this.create.bank_id = this.create.operatingUnitBank.BankID
      this.create.bank_name = this.create.operatingUnitBank.BankName
      this.create.account_name = this.create.operatingUnitBank.AccountName
      this.create.account_number = this.create.operatingUnitBank.AccountNumber
      this.create.bank_branch_name = this.create.operatingUnitBank.BranchName
      this.create.date_payment = moment(this.date_clearing).format("DD MMMM YYYY")
      this.create.date_deposit = moment(this.date_deposit).format("DD MMMM YYYY")
      console.log(this.create)
      this.$http
				.put("/api/v1/cash-bank/update", this.create)
				.then((resp) => {
           this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to create",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch(e => {
          this.$vs.notify({
              title: "Error",
              text: "Failed to create",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
        })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getBankForm(ID) {
      this.$http.get('/api/v1/cash-bank/form-bank/' + ID).then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.optionOperatingUnitBank = r.data.operatingUnitBank
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
          r.data.operatingUnitBank.map(r => {
            if (r.BankID == this.selected.BankID && r.BranchName == this.selected.BankBranchName && r.AccountNumber == this.selected.AccountNumber) {
              this.create.operatingUnitBank = r
              console.log(this.create.operatingUnitBank)
            }
          })
        }).catch((e) => {
          console.log(e)
        })
    },
    getGeneralForm() {
      this.$vs.loading();

      this.$http.get('/api/v1/cash-bank/form-general').then((r) => {
          // this.optionTerritory = r.data.territory
          this.optionTerritory = r.data.territories
          r.data.territories.map(r => {
            if (r.ID == this.selected.TerritoryID) {
              this.create.territory = r 
            }
          });

          this.create.id = this.selected.ID
          this.create.method = this.optionMethod[this.selected.Method-1]
          this.optionBank = r.data.bank
          r.data.bank.map(r => {
            console.log(r, r.ID, this.selected.GiroBankID)
            if (r.ID == this.selected.GiroBankID) {
              this.bank = r
            }
          })
          this.getBankForm(this.selected.TerritoryID)
          

          this.create.amount = this.formatPrice(this.selected.DepositValue)
          this.date_clearing = this.selected.DatePayment
          this.date_giro = this.selected.GiroDate
          this.date_deposit = this.selected.Date
          this.create.number_of = this.selected.GiroNumber
          this.create.deposit_number = this.selected.DepositNumber
          this.create.note = this.selected.Note
          this.getOptionCustomer(this.selected.PersonName)
          // this.create.customer = this.selected.DepositNumber
          // this.create.bank = this.selected.GiroBankID
          this.$vs.loading.close();

        }).catch((e) => {
          console.log(e)
        })
    },
    getOptionCustomer(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http.get('/api/v1/master/customer',{
            params: {
                search: query,
                length: 100,
            }
        }).then((r) => {
          if (r.code == 200) {
            this.optionCustomer = r.data.records
            this.optionCustomer.map(r => {
              if (r.name == this.selected.PersonName) {
                this.create.customer = r
              }
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Customer option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          console.log(r)
        }).catch((e) => {
          console.log(e)
        })
    },
  },
  mounted() {
    console.log(this.selected)
    console.log("mounted")
    this.getGeneralForm()
  },
  computed: {},
  watch: {
    "create.method"() {
      if (this.create.method == "Cash") {
        this.hideForm = true
      } else {
        this.hideForm = false
      }
    },
    "selected.ID"() {
      this.$nextTick(() => {
        console.log(this.selected)
        this.getGeneralForm()
      })
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>